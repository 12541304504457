<template>
  <div>
    <div id="rw-content">
      <!-- rw title -->
      <div
        style="background:linear-gradient(135deg, rgb(48, 71, 236) 0%, rgb(15, 34, 167) 100%); transition: height 160ms ease-out 0s;color:white"
      >
        <div class="container p-2">
          <!-- 1st time  -->
          <div class="d-inline-flex" v-if="!isActive">
            <img src="images/rw.png" id="rwicon" class="m-2" /> <br />
            <div class="tagline ml-2">
              <h5 class="font-weight-bold mt-2 mb-0">Rumahweb Indonesia</h5>
              <small>Painless hosting solution</small>
            </div>
          </div>

          <!-- was having a conversation with staf -->
          <div v-else>
            <div
              class="d-flex d-inline-flex align-items-center"
              style="width: 100%"
            >
              <div class="divBackArrow">
                <i class="fa fa-chevron-left"></i>
              </div>
              <div
                class="d-flex d-inline-flex align-items-center mx-2 cs-profile"
                style="width: 100%"
                @click="expandProfile()"
              >
                <img
                  src="http://www.gravatar.com/avatar/850cb023e169427bb9eb3f3b18d0f091?d=identicon"
                  class="m-1"
                  style="border-radius: 50%; width: 32px; height: 32px;"
                />
                <div class="mx-2">
                  <div>
                    John Doe <br />
                    <small style="color: rgba(255, 255, 255, 0.7)"
                      >Anak Magang Di Rumahweb</small
                    >
                  </div>
                </div>
                <!-- <div class="text-muted">
									<small><i class="fa fa-quote-right"></i> Duit adalah jalan ninjaku</small>
								</div> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- rw content -->
      <div class="container my-3" v-if="!isActive">
        <p class="alert alert-primary text-center">
          To help us serve you better, please provide some information before we
          begin your chat.
        </p>
        <form @submit.stop.prevent="onSubmit">
          <div class="form-group">
            <select class="form-control" v-model="form.departement">
              <option value="sales">Sales - Offline</option>
              <option value="billing">Billing - Online</option>
              <option value="technical">Technical Support - Offline</option>
            </select>
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="Masukan nama"
              v-model="form.name"
            />
          </div>
          <div class="form-group">
            <input
              type="email"
              class="form-control"
              placeholder="Masukan email"
              v-model="form.email"
            />
          </div>
          <div class="form-group">
            <textarea
              class="form-control"
              placeholder="Masukan pertanyaan"
              v-model="form.question"
            ></textarea>
          </div>
          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
      </div>

      <!-- rw content active -->
      <div
        v-else
        id="chat"
        class="container"
        style="height: 19.7em;overflow: hidden auto;"
      >
        <chatActive
          :data="form"
          :messages="messages"
          @chooseDepartement="onChooseDeparetement"
        ></chatActive>
      </div>

      <!-- rwloader -->
      <loading
        :active.sync="isLoading"
        :is-full-page="true"
        color="#3498db"
        loader="bars"
      ></loading>

      <!-- textarea for chat-->
      <div
        class="d-inline-flex"
        style="position: absolute;bottom: 0;bottom:0;width: 100%"
        v-if="isActive"
      >
        <TwemojiTextarea
          :emojiData="emojiDataAll"
          :emojiGroups="emojiGroups"
          :enableSendBtn="false"
          @enterKey="onEnterKey"
          @contentChangedHtml="contentChanged"
          class="flex-grow-1"
          placeholder="Write something..."
        >
        </TwemojiTextarea>
        <div class="p-2" style="background:#F7F7F7">
          <span class="hiddenFileInput">
            <input type="file" @change="onChooseFile" />
          </span>
          <!-- <i class="fa fa-paper-plane text-info  p-2"></i> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import loading from "vue-loading-overlay";
import chatActive from "@/components/client/ChatActive.vue";
import { helper, message, avatar } from "@/core/mixins/mixin_helper";
import "vue-loading-overlay/dist/vue-loading.css";

//emot
import { TwemojiTextarea } from "@kevinfaguiar/vue-twemoji-picker";
import EmojiAllData from "@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-all-groups.json";
import EmojiGroups from "@kevinfaguiar/vue-twemoji-picker/emoji-data/emoji-groups.json";
export default {
  mixins: [helper, message, avatar],
  components: {
    loading,
    chatActive,
    TwemojiTextarea
  },
  data() {
    return {
      isActive: false,
      isLoading: false,
      form: {
        name: "",
        email: "",
        question: "",
        departement: "sales"
      },
      message: "",
      messages: [
        {
          name: "Naruto Uzumaki",
          time: "23 Jan 12:05",
          image: this.mixGravatar("Naruto Uzumaki"),
          boot: false,
          tipe: "msg",
          fromMe: false,
          message:
            "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        },
        {
          name: "John Doe",
          time: "23 Jan 15:37",
          image: this.mixGravatar("johndoe@email.com"),
          bot: false,
          tipe: "message",
          fromMe: true,
          message: `Naik naik ke puncak gunung <img class="emoji" draggable="false" alt="😂" src="https://twemoji.maxcdn.com/2/72x72/1f602.png">`
        },
        {
          name: "Bot",
          time: "23 Jan 12:05",
          image: null,
          bot: true,
          tipe: "alert",
          fromMe: null,
          message: "John Doe meninggalkan percakapan"
        },
        {
          name: "Naruto Uzumaki",
          time: "23 Jan 12:05",
          image: this.mixGravatar("Naruto Uzumaki"),
          bot: false,
          fromMe: false,
          tipe: "message",
          message:
            "Mendaki gunung lewati lembah sungai mengalir indah ke samudra"
        },
        {
          name: "Bot",
          time: "23 Jan 15:37",
          image: this.mixGravatar("rwbot"),
          bot: true,
          tipe: "chooseDepartement",
          fromMe: true,
          message: null
        },
        {
          name: "Bot",
          time: "3 March 17:00",
          image: this.mixGravatar("rwbot"),
          bot: true,
          tipe: "rating",
          fromMe: true,
          messages: null
        }
      ]
    };
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * register client
     * --------------------------------------------------------------
     */
    onSubmit: function() {
      this.isLoading = !this.isLoading;
      setTimeout(() => {
        localStorage.setItem("rwOn", true);
        this.isActive = true;
        this.isLoading = !this.isLoading;
      }, 2000);
    },

    /**
     * --------------------------------------------------------------
     * trigger ke model message karna textarea nya menggunakn 3th part
     * --------------------------------------------------------------
     */
    contentChanged(message) {
      this.message = message;
    },

    /**
     * --------------------------------------------------------------
     * when enter key was clicked by client
     * --------------------------------------------------------------
     */
    onEnterKey() {
      this.sendMessage();
    },

    /**
     * --------------------------------------------------------------
     * send message
     * --------------------------------------------------------------
     */
    sendMessage: function() {
      if (this.message.trim() != "") {
        let msg = this.mixGenerateMessage.human(
          "Naruto Uzumaki",
          this.message,
          false
        );
        this.messages.push(msg);

        // delay karna tidak bisa menyesuaikan dom
        setTimeout(() => {
          this.scrollToEndChat();
          // dummy balasan dari staf
          msg = this.mixGenerateMessage.human(
            "Addin RW",
            `Pesan :: ${this.message}`,
            true
          );
          this.messages.push(msg);

          setTimeout(() => {
            this.scrollToEndChat();
            // reset msg
            $("#twemoji-textarea").html("");
            this.message = "";
          }, 1000);
        }, 600);
      } else {
        this.$swal("error", "Message is required", "error");
      }
    },

    /**
     * --------------------------------------------------------------
     * emit from child pilih departemen
     * --------------------------------------------------------------
     */
    onChooseDeparetement: function(depName) {
      let msg = this.mixGenerateMessage.bot(
        "alert",
        `Anda sedang dihubungkan dengan <b>${depName}</b>, mohon ditunggu`
      );
      this.messages.push(msg);

      // delay karna tidak bisa menyesuaikan dom
      setTimeout(() => {
        this.scrollToEndChat();
        msg = this.mixGenerateMessage.bot(
          "alert",
          `Terhubung dengan <b>Addin RW</b> departement <b>${depName}</b> jabatan <b>Junior ${depName}</b>`
        );
        this.messages.push(msg);

        setTimeout(() => {
          this.scrollToEndChat();
          msg = this.mixGenerateMessage.human(
            "Addin RW",
            `Halo, dengan bagian ${depName} ada yang bisa dibantu ?`,
            true
          );
          this.messages.push(msg);

          setTimeout(() => {
            this.scrollToEndChat();
          }, 1000);
        }, 1000);
      }, 600);
    },

    /**
     * --------------------------------------------------------------
     * ketika memilih file validasi ekstensi
     * --------------------------------------------------------------
     */
    onChooseFile: function(e) {
      let image = e.target.files[0];
      let allowedExt = [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/bmp"
      ];

      if (allowedExt.includes(image.type)) {
        let size = Math.round(image.size / 1024);
        if (size > 2048) {
          this.$swal({
            title: "Error",
            html: `Ukuran file maksimal 2MB!`,
            icon: "error"
          });
        } else {
          let msg = URL.createObjectURL(image);
          msg = `<img src="${msg}" class="img-fluid">`;
          msg = this.mixGenerateMessage.human("Naruto Uzumaki", msg, false);
          this.messages.push(msg);

          setTimeout(() => {
            this.scrollToEndChat();

            msg = this.mixGenerateMessage.human(
              "Addin RW",
              `Anda mengirim gambar ${image.type} diatas`,
              true
            );
            this.messages.push(msg);
            setTimeout(() => {
              this.scrollToEndChat();
            }, 800);
          }, 600);
        }
      } else {
        // alert when fail
        let ext = image.type.split("/");
        ext = ext[1].split("."); // get last ext
        this.$swal({
          title: "Error",
          html: `<b>${ext[ext.length - 1]}</b> tidak diizinkan! <br> \
	  			<small><b>PNG | JPEG | JPG | GIF | BMP </b></small>`,
          icon: "error"
        });
      }
    },

    /**
     * --------------------------------------------------------------
     * showing profile
     * --------------------------------------------------------------
     */
    expandProfile: function() {
      $(".cs-profile").css("height", "100px");
    },

    /**
     * --------------------------------------------------------------
     * to scroll ed of element chat, must be use timeout
     * tidak dapat manipulasi dom langsung
     * --------------------------------------------------------------
     */
    scrollToEndChat: function() {
      $("#chat").scrollTop(parseInt($("#chat")[0].scrollHeight));
    }
  },
  computed: {
    emojiDataAll() {
      return EmojiAllData;
    },
    emojiGroups() {
      return EmojiGroups;
    }
  },
  mounted() {
    this.isActive = localStorage.getItem("rwOn") ? true : false;
  }
};
</script>

<style scoped>
#rwicon {
  /*width: 40px;*/
  height: 40px;
}

/**
	 * maipulate hidden button upload
	 */
.hiddenFileInput > input {
  height: 100%;
  width: 40px;
  opacity: 0;
}
.hiddenFileInput {
  display: inline-block;
  overflow: hidden;
  background: center center no-repeat;
  background-image: url("../assets/svg/camera-solid.svg");
  margin-top: 19%;
}

.divBackArrow {
  width: 43px;
  padding: 16px;
  /*padding-left: 0px;*/
}
.divBackArrow:hover {
  background: red;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.cs-profile {
  cursor: pointer;
}
.cs-profile:hover {
  /*width: 100%;*/
  height: 48px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.3);
}
</style>
